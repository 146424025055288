export const locations = [{
    id: "9a65b218-909c-11ed-a1eb-0242ac120002",
    urlQuery: "55winges",
    address: "55 Winges Rd. Unit #4, Vaughan, Ontario L4L 6B4",
    email: "vaughan@kudosgolf.com",
    phone: 6476915528,
    name: "Vaughan, ON",
    latitude: 43.7858587,
    longitude: -79.5555959,
    endpoints: {
      SCHEDULER: "https://app.acuityscheduling.com/schedule.php?owner=22197244",
      CATALOG: "https://app.acuityscheduling.com/catalog.php?owner=22197244",
      MEMBERSHIPS: "https://app.acuityscheduling.com/catalog.php?owner=22197244&category=Memberships",
      GIFT_CERTIFICATES: "https://app.acuityscheduling.com/catalog.php?owner=22197244&category=Gift+Certificates",
      GOOGLE_MAPS_LOC: "https://www.google.com/maps/place/Kudos+Golf/@43.7872164,-79.5522165,15.68z/data=!4m5!3m4!1s0x882b2f3729b68e39:0xbdd3cba1c2efecab!8m2!3d43.7858548!4d-79.5534072?hl=en",
    },
    price: {
      openBay: 20,
      privateBay: 30,
    },
    map: {
      desktop: "images/contact-us/kudos-golf-55winges.jpg",
      mobile: "images/contact-us/kudos-golf-55winges-mobile.jpg",
    },
    floorplan: {
      image: "images/how-it-works/kudos-golf-floor-plan-55winges.png",
      openBays: true,
      privateBays: true,
      privateBaysConnected: true,
    },
    socials: {
      facebook: "https://www.facebook.com/kudosgolf/",
      instagram: "https://www.instagram.com/kudosgolf/",
    },
  },
  {
    id: "9a65b72c-909c-11ed-a1eb-0242ac120002",
    urlQuery: "2301millar",
    address: "2301 Millar Ave Bay E, Saskatoon, Saskatchewan S7K 2Y1",
    email: "saskatoon@kudosgolf.com",
    phone: 3068022227,
    name: "Saskatoon, SK",
    latitude: 52.1503738,
    longitude: -106.6753705,
    endpoints: {
      SCHEDULER: "https://KudosGolfYXE.as.me",
      CATALOG: "https://app.squarespacescheduling.com/catalog.php?owner=27897765",
      MEMBERSHIPS: "https://app.squarespacescheduling.com/catalog.php?owner=27897765&category=Memberships",
      GIFT_CERTIFICATES: "https://app.squarespacescheduling.com/catalog.php?owner=27897765&category=Gift+Certificates",
      GOOGLE_MAPS_LOC: "https://www.google.com/maps/place/2301+Millar+Ave,+Saskatoon,+SK+S7K+2Y1/@52.1619359,-106.6517627,17z/data=!3m1!4b1!4m5!3m4!1s0x5304f6897cd6da41:0xc71eca7e9519fdd4!8m2!3d52.1619326!4d-106.649574",
    },
    price: {
      openBay: 30,
      privateBay: false,
    },
    map: {
      desktop: "images/contact-us/kudos-golf-2301millar.jpg",
      mobile: "images/contact-us/kudos-golf-2301millar-mobile.jpg",
    },
    floorplan: {
      image: "images/how-it-works/kudos-golf-floor-plan-2301millar.png",
      openBays: true,
      privateBays: false,
      privateBaysConnected: false,
    },
    socials: {
      facebook: "https://www.facebook.com/kudosgolfyxe/",
      instagram: "https://www.instagram.com/kudosgolfyxe/",
    },
  },
  {
    id: "d9ba65c1-a19f-414c-903e-c781dd0edfc0",
    banner: `<strong>New Price: </strong>Exceptional Value.`,
    urlQuery: "2172wyecroft",
    address: "2172 Wyecroft Rd Unit #7, Oakville, Ontario L6L 6R1",
    email: "oakville@kudosgolf.com",
    phone: 6477257745,
    name: "Oakville, ON",
    latitude: 43.4163525,
    longitude: -79.7264839,
    endpoints: {
      SCHEDULER: "https://kudos2172wyecroft.as.me/schedule.php",
      CATALOG: "https://app.acuityscheduling.com/catalog.php?owner=30967785",
      MEMBERSHIPS: "https://app.acuityscheduling.com/catalog.php?owner=30967785&category=Memberships",
      GIFT_CERTIFICATES: "https://app.acuityscheduling.com/catalog.php?owner=30967785&category=Gift+Certificates",
      GOOGLE_MAPS_LOC: "https://www.google.com/maps/place/2172+Wyecroft+Rd+Unit%23+7,+Oakville,+ON+L6L+6R1/@43.415994,-79.7253574,17z/data=!3m1!4b1!4m6!3m5!1s0x882b5def0fbfffff:0xf23e81f83d6e7c6c!8m2!3d43.415994!4d-79.7253574!16s%2Fg%2F11js2vpptf?entry=ttu",
    },
    price: {
      openBay: 19,
      privateBay: 22,
    },
    bayConfiguration: {
      connected: "",
    },
    map: {
      desktop: "images/contact-us/kudos-golf-2172wyecroft.jpg",
      mobile: "images/contact-us/kudos-golf-2172wyecroft-mobile.jpg",
    },
    floorplan: {
      image: "images/how-it-works/kudos-golf-floor-plan-2172wyecroft.png",
      openBays: true,
      privateBays: true,
      privateBaysConnected: false,
    },
    socials: {
      facebook: "https://www.facebook.com/profile.php?id=61553877581251",
      instagram: "https://www.instagram.com/kudosgolfoakville/",
    },
  },
];

export const DEFAULT_LOCATION = locations[0];

export const formatAddress = (address) => {
  const [street, city, provpostal] = address.split(",");

  const matchResult = provpostal.match(/[a-z0-9]{3}\s[a-z0-9]{3}?$/i);
  let postalCode, province;
  if (matchResult) {
    postalCode = matchResult[0].trim();
    province = provpostal.slice(0, matchResult.index - 1).trim();
  }
  return {
    street,
    city: city.trim(),
    province,
    postalCode,
  };
};

export default locations;