import React, { Component } from 'react';
import PropTypes from 'prop-types';
import router from 'next/router';
import Link from 'next/link'
import styles from './CTA.module.scss'

export default class CTA extends Component {
  onClick = (e, href) => {
    if (this.props.target !== '_blank') {
      e.preventDefault();
    }
    
    this.trackCTA(e);
    if (typeof this.props.onClick === 'function') this.props.onClick(e);
    
    if (this.props.target !== '_blank' && href) {
      router.push(href);
    }
  }

  trackCTA = e => {
    const { analytics, src, dest } = this.props
    if (analytics && analytics.event) {
        analytics.event({
          category: 'cta',
          action: 'click',
          label: `${src}-to-${dest}`
        })
    }
  }

  render() {
    const { text, href, className, target, children } = this.props
    return (
      <Link className={styles.cta} href={ href }>
        <a className={className} onClick={e => this.onClick(e, href)} title={text} target={target} >{ text || children }</a>
      </Link>
    )
  }
}

CTA.propTypes = {
  text: PropTypes.any,
  analytics: PropTypes.object,
  className: PropTypes.string,
  target: PropTypes.string,
  href: PropTypes.string,
  src: PropTypes.string.isRequired,
  dest: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

CTA.defaultProps = {
  className: '',
  href: ''
}